<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].website.indexImageTitle" class="hero-content">
					<div class="columns column12">
						<div class="content">
							<h2 class="hero-title">
								{{ defaults[locale].website.indexImageTitle }}
							</h2>
							<h3 class="hero-subtitle">
								{{ defaults[locale].website.indexImageSubtitle }}
							</h3>
						</div>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:content="page.content"
			:subtitle="page.subtitle"
			:booking-id="page.bookingID"
		/>

		<section class="booking-benefits">
			<div class="row">
				<div class="columns column4 left bg-image-content">
					<h2>{{ defaults[locale].website.benefitsHeader }}</h2>
					<ul>
						<li v-for="(benefit, key) in defaults[locale].website.bookingBenefits" :key="key">
							<font-awesome-icon icon="fal fa-circle-check" size="1x" />{{ benefit.content }}
						</li>
					</ul>
					<book-button class="button button-cta reserve-now">
						{{ $t('bookNow') }}
					</book-button>
				</div>
				<picture>
					<source
						v-if="defaults[locale].website.bannerImageWebp"
						:srcset="defaults[locale].website.bannerImageWebp"
						type="image/webp"
					/>
					<source :srcset="defaults[locale].website.bannerImage" />
					<img
						class="bg-image"
						:src="defaults[locale].website.bannerImage"
						:alt="defaults[locale].website.bannerImageAlt"
						loading="lazy"
					/>
				</picture>
			</div>
		</section>

		<promoblocks :header="defaults[locale].website.indexPromotionTitle" :items="promoblocksData" :amount="3" />

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks">
			<template #contentBlockContent>
				<div class="row small-row">
					<div class="columns column12 align-center">
						<div v-parse-links class="columns column12 align-center" v-html="page.contentBlockContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].website.newsletterHeader"
			:content="defaults[locale].website.newsletterContent"
			:button="defaults[locale].website.newsletterButton"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;
	color: #fff;
	text-align: center;
	max-width: 80%;
	margin: 0 auto;
	animation: fade-in 1500ms ease-out;

	.content {
		color: #fff;
		text-align: center;
		max-width: 80%;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: $heading-font-family;

		h2 {
			font-size: 4em;
			color: #fff;
			font-weight: 300;
		}

		h3 {
			font-weight: 400;
			font-size: 20px;
			line-height: 100%;
			letter-spacing: 0.15em;
			text-transform: uppercase;
			color: #fff;
		}
	}
}

.booking-benefits {
	position: relative;
	padding: 50px 0;
	background: linear-gradient(90deg, #eeebeb 79%, #fff 21%);

	.row {
		position: relative;
		padding: 100px 20px;
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			position: relative;
			line-height: 44px;
			vertical-align: middle;

			svg {
				margin: 4px 9px -4px 0;
				font-size: 26px;
				display: inline-block;
				color: $cta-color;
			}
		}
	}
}

.bg-image {
	position: absolute;
	inset: 0 0 0 18%;
	height: 100%;
	width: 82%;
	object-fit: cover;
	z-index: 1;
}

.bg-image-content {
	background: #fff;
	padding: 50px;
	z-index: 3;
}

@media (max-width: 600px) {
	.booking-benefits {
		background: #eeebeb;
	}

	.bg-image-content {
		padding: 30px 25px;
	}

	.hero-image {
		.hero-content {
			.content {
				h2 {
					font-size: 3.4em;
				}
			}
		}
	}
}
</style>
